<template>

  <b-card-code
    title="Upload Course Material"
  >
   <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
  <ValidationObserver v-slot="{ handleSubmit}" ref="form">
  <b-form @submit.prevent="handleSubmit(storeAttachment)">
    <b-alert
      variant="danger"
      show
      dismissible
      v-if="validationErrors && validationErrors.message"
    >
      <div class="alert-body">
        <span><strong>{{validationErrors.message}}</strong></span>
      </div>
    </b-alert>
    <b-row class="mt-2">
    <b-col md="6">
        <b-form-group
            label-for="mc-email"
            label="Curso"
        >
        <ValidationProvider rules="required" name="Curso" v-slot="{ errors }">
          <v-select
            v-model="material.course_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="courses"
            placeholder = 'Select Course'
            :reduce="course => course.id"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
        </b-form-group>
      </b-col>
    <b-col md="6">
    
    <b-form-group
            label="Upload PDF"
            label-for="mc-first-name"
          >
    <ValidationProvider rules="required|validatePdf" name="Course Attachment" v-slot="{ errors }">
    <b-form-file
        accept="application/pdf"
        v-model="material.course_attachment"
        name="course_attachment"
    >
    <template
        slot="file-name"
        slot-scope="{ names }"
    >
        <b-badge variant="primary">
            {{ names[0] }}
        </b-badge>
    </template>
    </b-form-file>
    <small class="text-danger">{{ errors[0] }}</small>
    </ValidationProvider>
    </b-form-group>
     
      </b-col>
        <b-col md="6">
          <b-form-group
            label="Attachment Name"
            label-for="mc-first-name"
          >
            <ValidationProvider rules="required" name="Attachment Name" v-slot="{ errors }">
              <b-form-input
                v-model="material.attachment_name"
                id="mc-first-name"
                placeholder="Attachment Name"
                name="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        
        <!-- submit and reset -->
       
      </b-row>
      <b-row>
       <b-col>
        
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Create
          </b-button>
         
        </b-col>
        </b-row>
    </b-form>
  </ValidationObserver> 
  </b-overlay>
   </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BFormDatepicker, BBadge, BInputGroup, BAlert, BInputGroupAppend, BCol, BAvatar,BCardText, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormFile,BMedia, BMediaAside, BOverlay, BMediaBody, BLink, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CourseService from '@/services/course.service.js';
const initialState = {
  attachment_name: '',
            course_attachment: null,
            course_id: '',
            type: 'material'
}
export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormFile,
    BCardText,
    BFormDatepicker,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    BBadge
  },
  directives: {
    Ripple,
  },
  data() {
    return {
        
        material: {
            attachment_name: '',
            course_attachment: null,
            course_id: '',
            type: 'material'
        },
        logoImg: require('@/assets/images/pages/logo-1.png'),
        avatar: '@/assets/images/portrait/small/avatar-s-11.jpg',
        showOverlay: false,
        errors: '',
        courses: [],
    }
  },
  computed: {
    validationErrors(){
      return this.errors.data
    },
  },
  methods: {
    storeAttachment() { 
        var data = this.storeFormData(); 
      this.showOverlay = true;
          CourseService.storeMaterial(data).then(response => {
            this.showOverlay = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Course Material Uploaded Successfully',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                // this.$router.push('/courses');
            }).catch(error => {
              this.showOverlay = false;
              this.errors = error.response
            });
    },
    getCourses()
    {
      // CourseService.getAll().then(response => {
      //   this.courses = response.data.data.courses; 
      //   }).catch(error => {
      //       console.log(error)
      //   });
      CourseService.getAll().then(response => {
        let getCourses = response.data.data.courses;
        this.courses = getCourses.filter(course => {
            if(course.parent_id == null) {
                return course;
            }
        })
        }).catch(error => {
            console.log(error)
        });
    },
    storeFormData()
    {
      let formData = new FormData();
      for(let materialKey in this.material) {
          formData.append(materialKey, this.material[materialKey]);
      }
      return formData;
    },
  },
  created() {
      
      this.getCourses();
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
